import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Popup } from "../../Popup";
import { ALButton } from "../../ALComponents";

import IconClose from "../../icons/svgs/close-round.svg";
import IconCheck from "../../icons/svgs/check.svg";

import "./styles-modal.scss";

function ReactivateSubscriptionModal({ isOpen, subscriptionStatus, onAction = () => {}, onClose }) {
  const [isReactivateDone, setIsReactivateDone] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (subscriptionStatus !== "ACTIVE") {
      setIsReactivateDone(false);
    }
  }, [subscriptionStatus]);

  const onReactivateSubscription = async () => {
    setIsLoading(true);

    onAction()
      .then((luxeActionSuccessful) => {
        if (luxeActionSuccessful) {
          setIsReactivateDone(true);
        } else {
          setErrorMessage("Something went wrong, please try again later.");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Popup
      open={isOpen}
      modal
      closeOnDocumentClick
      className="subscription-modal"
      onClose={onClose}
    >
      <button type="button" className="close-icon" onClick={onClose}>
        <IconClose height="30" width="30" fill="#FFF" stroke="#2d2927" />
      </button>
      <h3 className="h3 h3--bold">Reactivate membership</h3>
      <p className="subtext mb-40">
        Your membership will be reactivated and you will be charged $39.99 on your next billing
        date.
      </p>
      <ALButton
        size="medium"
        variant="primary"
        fullWidth
        onClick={onReactivateSubscription}
        disabled={isLoading || isReactivateDone || !!errorMessage}
        isLoading={isLoading}
      >
        Confirm Reactivation
      </ALButton>

      {isReactivateDone && (
        <div className="mt-10 subscription-confirmation">
          <IconCheck height="30" width="30" fill="#FFF" stroke="#2d2927" className="mr-5" />
          <span className="subtext">
            Success! Please see membership details in your account for your next billing date.
          </span>
        </div>
      )}
      {errorMessage && (
        <div className="mt-10 subscription-error">
          <span className="subtext">{errorMessage}</span>
        </div>
      )}
    </Popup>
  );
}

ReactivateSubscriptionModal.propTypes = {
  isOpen: PropTypes.bool,
  subscriptionStatus: PropTypes.string,
  onAction: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

export default ReactivateSubscriptionModal;
